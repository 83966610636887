<script>
  import tippy from 'tippy.js'

  /**
   * A wrapper component for Tippy.js, for when you want another component to trigger a tooltip on hover or focus.
   */
  export default {
    props: {
      /** The background color of the tooltip. */
      color: {
        type: String,
        default: 'black',
        validator: (value) => ['black', 'dawn-lt4'].includes(value),
      },
      /** The preferred placement of the tooltip. See https://atomiks.github.io/tippyjs/v6/all-props/#placement for all accepted values. */
      placement: {
        type: String,
        default: 'top',
      },
      /** Displaces the tooltip from its reference element in pixels (skidding and distance). */
      offset: {
        type: Array,
        default: () => [0, 12],
      },
      /** Whether the wrapper element should be focusable. Only enable this if the element you’re wrapping is not focusable itself. */
      focusable: {
        type: Boolean,
        default: false,
      },
      /** Whether the tooltip has interactive content inside of it, so that it can be hovered over and clicked inside without hiding. */
      interactive: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        tippy: null,
        referenceRef: null,
        tooltipContentRef: null,
      }
    },
    watch: {
      color() {
        this.tippy.setProps({
          theme: this.color,
        })
      },
      placement() {
        this.tippy.setProps({
          placement: this.placement,
        })
      },
      offset() {
        this.tippy.setProps({
          offset: this.offset,
        })
      },
      focusable() {
        this.tippy.setProps({
          trigger: `mouseenter focusin ${this.focusable ? 'click' : ''}`,
        })
      },
      interactive() {
        this.tippy.setProps({
          interactive: this.interactive,
        })
      },
    },
    mounted() {
      this.tippy = tippy(this.referenceRef, {
        content: this.tooltipContentRef,
        ignoreAttributes: true,
        theme: this.color,
        placement: this.placement,
        offset: this.offset,
        trigger: `mouseenter focusin ${this.focusable ? 'click' : ''}`,
        hideOnClick: false,
        interactive: this.interactive,
        interactiveDebounce: 100,
      })
    },
    updated() {
      this.tippy.setContent(this.tooltipContentRef)
    },
    beforeDestroy() {
      this.tippy.destroy()
    },
  }
</script>

<template>
  <span>
    <span v-ref="referenceRef" :tabindex="focusable ? 0 : undefined" class="block rounded-inherit">
      <!-- @slot The component(s) that trigger the tooltip. -->
      <slot />
      <span class="hidden">
        <span v-ref="tooltipContentRef">
          <!-- @slot The tooltip’s content. -->
          <slot name="tooltip" />
        </span>
      </span>
    </span>
  </span>
</template>

<style lang="postcss">
  /* purgecss start ignore */
  @import 'tippy.js/dist/tippy.css';

  .tippy-box {
    @apply border border-white border-opacity-50 bg-clip-padding text-white font-normal text-sm text-center leading-snug;

    .tippy-content {
      @apply px-4 py-3;
    }

    &[data-theme='black'] {
      @apply bg-black;

      .tippy-arrow {
        @apply text-black;
      }
    }

    &[data-theme='dawn-lt4'] {
      @apply min-w-72 bg-dawn-lt4 text-black text-3xs;

      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);

      .tippy-arrow {
        @apply text-dawn-lt4;
      }
    }
  }
  /* purgecss end ignore */
</style>
